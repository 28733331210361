import { AxiosInstance } from 'axios';
import { AxiosParam, create, send } from 'summer';
import { ImportacaoParam } from '../models/dtos/ger/importacaoParam';
import { BaixarDocumentosParam } from '../models/dtos/mic/baixarDocumentosParam';
import { BuscaCaixaPostalParam } from '../models/dtos/mic/buscaCaixaPostalParam';
import { CaixaPostal } from '../models/dtos/mic/caixaPostal';
import { ConfiguracaoMIC } from '../models/dtos/mic/configuracaoMIC';
import { ConsultaCNDParam } from '../models/dtos/mic/consultaCNDParam';
import { ConsultaPagamentoParam } from '../models/dtos/mic/consultaPagamentoParam';
import { DashboardParam } from '../models/dtos/mic/dashboardParam';
import { DeclaracaoPGDASParam } from '../models/dtos/mic/declaracaoPGDASParam';
import { EmpresaCND } from '../models/dtos/mic/empresaCND';
import { EmpresaDASPGMEI } from '../models/dtos/mic/empresaDASPGMEI';
import { EmpresaDCTFWeb } from '../models/dtos/mic/empresaDCTFWeb';
import { EmpresaDeclaracaoPGDAS } from '../models/dtos/mic/empresaDeclaracaoPGDAS';
import { EmpresaMIC } from '../models/dtos/mic/empresaMIC';
import { EmpresaSICALC } from '../models/dtos/mic/empresaSICALC';
import { EmpresaSITFIS } from '../models/dtos/mic/empresaSITFIS';
import { GeracaoGuiaDCTFWebParam } from '../models/dtos/mic/geracaoGuiaDCTFWebParam';
import { GeracaoGuiaPGMEIParam } from '../models/dtos/mic/geracaoGuiaPGMEIParam';
import { GeracaoSITFISParam } from '../models/dtos/mic/geracaoSITFISParam';
import { OpcaoRegimeApuracaoParam } from '../models/dtos/mic/opcaoRegimeApuracaoParam';
import { TipoDocumentoDCTFWebEnum } from '../models/enumerated/mic/tipoDocumentoDCTFWebEnum';
import { getHeaders } from './ger.service';

function getService(): AxiosInstance {
  return create('/mic');
}

export function atualizarEmpresa({ arquivos, id, ...empresaMIC }: EmpresaMIC, param: AxiosParam<void>) {
  const formData = new FormData();
  Object.keys(empresaMIC).forEach(key => formData.append(key, empresaMIC[key]));
  formData.append('id', String(id || ''));
  for (const arquivo of arquivos || []) {
    formData.append('arquivos', arquivo.file, arquivo.nomeArquivo);
  }
  send(getService().post<void>('/atualizarEmpresa', formData), param);
}

export function salvarConfiguracaoGeral(
  {
    arquivos,
    isClone,
    diaEmissaoDctfweb,
    diaEmissaoDctfweb13,
    diaEmissaoPgdas,
    diaEmissaoPgmei,
    diaBuscaCaixaPostal,
    diaConsultaPagamentos,
    ...configuracaoMIC
  }: ConfiguracaoMIC,
  processarAutomacao: boolean,
  param: AxiosParam<void>
) {
  const formData = new FormData();
  Object.keys(configuracaoMIC).forEach(key => formData.append(key, configuracaoMIC[key] || ''));
  formData.append('diaEmissaoDctfweb', String(diaEmissaoDctfweb || ''));
  formData.append('diaEmissaoDctfweb13', String(diaEmissaoDctfweb13 || ''));
  formData.append('diaEmissaoPgdas', String(diaEmissaoPgdas || ''));
  formData.append('diaEmissaoPgmei', String(diaEmissaoPgmei || ''));
  formData.append('diaBuscaCaixaPostal', String(diaBuscaCaixaPostal || ''));
  formData.append('diaConsultaPagamentos', String(diaConsultaPagamentos || ''));
  for (const arquivo of arquivos || []) {
    formData.append('arquivos', arquivo.file, arquivo.nomeArquivo);
  }
  send(getService().post<void>(`/salvarConfiguracaoGeral/${processarAutomacao}`, formData), param);
}

export function buscarMensagens(buscaCaixaPostalParam: BuscaCaixaPostalParam, param: AxiosParam<void>) {
  send(getService().post<void>('/buscarMensagens', buscaCaixaPostalParam, getHeaders()), param);
}

export function buscarDetalheMensagem(idUsuario: number, caixaPostal: CaixaPostal, param: AxiosParam<CaixaPostal>) {
  send(getService().post<CaixaPostal>(`/buscarDetalheMensagem/${idUsuario}`, caixaPostal), param);
}

export function entregarDeclaracao(declaracaoPGDASParam: DeclaracaoPGDASParam, param: AxiosParam<void>) {
  send(getService().post<void>('/entregarDeclaracao', declaracaoPGDASParam, getHeaders()), param);
}

export function consultarExtrato(empresaDeclaracaoPGDAS: EmpresaDeclaracaoPGDAS, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/consultarExtrato/${idUsuario}`, empresaDeclaracaoPGDAS), param);
}

export function findAllDeclaracoesMensais(referencia: string, param: AxiosParam<EmpresaDeclaracaoPGDAS[]>) {
  send(getService().get<EmpresaDeclaracaoPGDAS[]>(`/findAllDeclaracoesMensais/${referencia}`), param);
}

export function findAllDAS(referencia: string, param: AxiosParam<EmpresaDASPGMEI[]>) {
  send(getService().get<EmpresaDASPGMEI[]>(`/findAllDAS/${referencia}`), param);
}

export function gerarDASPGMEI(empresaDASPGMEI: EmpresaDASPGMEI, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/gerarDASPGMEI/${idUsuario}`, empresaDASPGMEI), param);
}

export function findAllEmpresasDCTFWeb(referencia: string, mensal: boolean, param: AxiosParam<EmpresaDCTFWeb[]>) {
  send(getService().get<EmpresaDCTFWeb[]>(`/findAllEmpresasDCTFWeb/${referencia}/${mensal}`), param);
}

export function gerarDocumentos(
  empresaDCTFWeb: EmpresaDCTFWeb,
  tipoDocumento: TipoDocumentoDCTFWebEnum,
  idUsuario: number,
  param: AxiosParam<string>
) {
  send(getService().post<string>(`/gerarDocumentos/${tipoDocumento}/${idUsuario}`, empresaDCTFWeb), param);
}

export function buscarDadosDashboard(dashboardParam: DashboardParam, param: AxiosParam<DashboardParam>) {
  send(getService().post<DashboardParam>('/buscarDadosDashboard', dashboardParam), param);
}

export function gerarDASPGDAS(empresaDeclaracaoPGDAS: EmpresaDeclaracaoPGDAS, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/gerarDASPGDAS/${idUsuario}`, empresaDeclaracaoPGDAS), param);
}

export function gerarComprovantePagamentoPGMEI(empresaDASPGMEI: EmpresaDASPGMEI, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/gerarComprovantePagamentoPGMEI/${idUsuario}`, empresaDASPGMEI), param);
}

export function gerarComprovantePagamentoPGDAS(empresaDeclaracao: EmpresaDeclaracaoPGDAS, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/gerarComprovantePagamentoPGDAS/${idUsuario}`, empresaDeclaracao), param);
}

export function gerarComprovantePagamentoDCTFWeb(empresaDCTFWeb: EmpresaDCTFWeb, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/gerarComprovantePagamentoDCTFWeb/${idUsuario}`, empresaDCTFWeb), param);
}

export function baixarPdf(chave: string, param: AxiosParam<string>) {
  send(getService().get<string>(`/baixarPdf/${chave}`), param);
}

export function desativarEmpresa(idEmpresaMIC: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/desativarEmpresa/${idEmpresaMIC}`), param);
}

export function baixarDocumentos(downloadDocumentosParam: BaixarDocumentosParam, param: AxiosParam<void>) {
  send(getService().post<void>('/baixarDocumentos', downloadDocumentosParam, getHeaders()), param);
}

export function gerarGuiasDCTFWeb(geracaoGuiaDCTFWebParam: GeracaoGuiaDCTFWebParam, param: AxiosParam<void>) {
  send(getService().post<void>('/gerarGuiasDCTFWeb', geracaoGuiaDCTFWebParam, getHeaders()), param);
}

export function gerarGuiasPGMEI(geracaoGuiaPGMEIParam: GeracaoGuiaPGMEIParam, param: AxiosParam<void>) {
  send(getService().post<void>('/gerarGuiasPGMEI', geracaoGuiaPGMEIParam, getHeaders()), param);
}

export function verificarVinculosAtivosEmpresa(idEmpresa: number, param: AxiosParam<boolean>) {
  send(getService().get<boolean>(`/verificarVinculosAtivosEmpresa/${idEmpresa}`), param);
}

export function findAllEmpresasSITFIS(param: AxiosParam<EmpresaSITFIS[]>) {
  send(getService().get<EmpresaSITFIS[]>('/findAllEmpresasSITFIS'), param);
}

export function gerarSITFIS(geracaoSITFISParam: GeracaoSITFISParam, param: AxiosParam<void>) {
  send(getService().post<void>('/gerarSITFIS', geracaoSITFISParam, getHeaders()), param);
}

export function findAllEmpresasCND(param: AxiosParam<EmpresaCND[]>) {
  send(getService().get<EmpresaCND[]>('/findAllEmpresasCND'), param);
}

export function consultarCND(consultaCNDParam: ConsultaCNDParam, param: AxiosParam<void>) {
  send(getService().post<void>('/consultarCND', consultaCNDParam, getHeaders()), param);
}

export function consultarPagamentos(consultaPagamentoParam: ConsultaPagamentoParam, param: AxiosParam<void>) {
  send(getService().post<void>('/consultarPagamentos', consultaPagamentoParam, getHeaders()), param);
}

export function opcaoRegimeApuracao(opcaoRegimeApuracaoParam: OpcaoRegimeApuracaoParam, param: AxiosParam<void>) {
  send(getService().post<void>('/opcaoRegimeApuracao', opcaoRegimeApuracaoParam, getHeaders()), param);
}

export function buscarChavePdfImpostoSICALC(idImposto: number, param: AxiosParam<string>) {
  send(getService().get<string>(`/buscarChavePdfImpostoSICALC/${idImposto}`), param);
}

export function findAllGuiasSICALC(referencia: string, param: AxiosParam<EmpresaSICALC[]>) {
  send(getService().get<EmpresaSICALC[]>(`/findAllGuiasSICALC/${referencia}`), param);
}

export function gerarComprovantePagamentoSICALC(empresaSICALC: EmpresaSICALC, idUsuario: number, param: AxiosParam<string>) {
  send(getService().post<string>(`/gerarComprovantePagamentoSICALC/${idUsuario}`, empresaSICALC), param);
}

export function validatePeriodImportCSV(data: ImportacaoParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validatePeriodImportCSV', data, getHeaders()), param);
}
